import React from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import TagManager from 'react-gtm-module';
import { OidcProvider } from '@axa-fr/react-oidc';
import env from '@beam-australia/react-env';
import { locales } from '@zetadisplay/engage-components/locales';
import { AuthLoader } from '@zetadisplay/engage-components/modules/auth';
import { TranslationProvider } from '@zetadisplay/zeta-localization';
import { SnackbarProvider } from 'notistack';

import AuthenticatingComponent from 'src/components/Auth/AuthenticatingComponent';
import AuthenticatingErrorComponent from 'src/components/Auth/AuthenticatingErrorComponent';
import CallbackErrorComponent from 'src/components/Auth/CallbackErrorComponent';
import CallbackSuccessComponent from 'src/components/Auth/CallbackSuccessComponent';
import SessionLostComponent from 'src/components/Auth/SessionLostComponent';
import ProtectedDependencyInjectionProvider from 'src/components/ProtectedDependencyInjectionProvider';
import { DEFAULT_OIDC_CONFIGURATION_NAME } from 'src/config/appConfig';
import oidcConfiguration from 'src/config/oidcConfiguration';
import FileDownloadSnackbar from 'src/modules/library/snackbar/file-download-snackbar';

/* istanbul ignore next */
if (process.env.NODE_ENV !== 'development' && process.env.NODE_ENV !== 'test') {
    TagManager.initialize({
        gtmId: env('GTM_ID'),
    });
}

const App = () => {
    return (
        <DndProvider backend={HTML5Backend}>
            <TranslationProvider defaultLocale="en-US" translations={locales}>
                <SnackbarProvider maxSnack={5} Components={{ FileDownloadSnackbar }}>
                    <OidcProvider
                        authenticatingComponent={AuthenticatingComponent}
                        authenticatingErrorComponent={AuthenticatingErrorComponent}
                        callbackErrorComponent={CallbackErrorComponent}
                        callbackSuccessComponent={CallbackSuccessComponent}
                        configuration={oidcConfiguration}
                        configurationName={DEFAULT_OIDC_CONFIGURATION_NAME}
                        loadingComponent={AuthLoader}
                        sessionLostComponent={SessionLostComponent}
                    >
                        <ProtectedDependencyInjectionProvider />
                    </OidcProvider>
                </SnackbarProvider>
            </TranslationProvider>
        </DndProvider>
    );
};

export default App;
