import React, { useEffect, useState } from 'react';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';
import { useTranslation } from '@zetadisplay/zeta-localization';
import { closeSnackbar, CustomContentProps, SnackbarContent } from 'notistack';

import { useOnFileDownloadProgressListener } from '../events/file-download-progress-event';

export interface FileDownloadSnackbarProps extends CustomContentProps {
    fileName: string;
}

// Adds the "are you sure you want to leave?" popup.
// Makes sure that the user doesn't accidentally cancel their download.
const unloadCallback = (event: BeforeUnloadEvent) => {
    event.preventDefault();
    event.returnValue = '';
    return '';
};

const FileDownloadSnackbar = React.forwardRef<HTMLDivElement, FileDownloadSnackbarProps>((props, ref) => {
    const { fileName, style, id } = props;
    const [snackbarProgress, setSnackbarProgress] = useState(0);
    const [done, setDone] = useState(false);
    const t = useTranslation();
    const snackbarCloseTimeout = 5000;

    useEffect(() => {
        window.addEventListener('beforeunload', unloadCallback);
        return () => window.removeEventListener('beforeunload', unloadCallback);
    }, []);

    useEffect(() => {
        if (done) {
            window.removeEventListener('beforeunload', unloadCallback);
        }
    }, [done]);

    useOnFileDownloadProgressListener(({ mediaId, progress }) => {
        if (mediaId !== id || !progress) return;

        const normalized = progress * 100;
        setSnackbarProgress(normalized);
        if (normalized === 100) {
            setDone(true);
            setTimeout(() => closeSnackbar(id), snackbarCloseTimeout);
        }
    });

    return (
        <SnackbarContent ref={ref} className=".MuiSnackbarContent-root" style={{ ...style, borderRadius: 4 }}>
            <Alert
                sx={{ bgcolor: done ? 'success' : 'background.paper', boxShadow: 2 }}
                severity={done ? 'success' : 'info'}
            >
                <AlertTitle>
                    {t.trans(done ? 'common.detail.downloaded' : 'common.notification.downloading')}
                </AlertTitle>

                {fileName}

                <Box sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                    <Box sx={{ width: '100%', mr: 1, minWidth: '180px' }}>
                        <LinearProgress sx={{ width: '100%' }} variant="determinate" value={snackbarProgress} />
                    </Box>

                    <Typography
                        data-testid="progress-percentage"
                        sx={{ minWidth: '3em' }}
                        variant="body2"
                        color="text.secondary"
                    >{`${Math.round(snackbarProgress)}%`}</Typography>
                </Box>
            </Alert>
        </SnackbarContent>
    );
});

FileDownloadSnackbar.displayName = 'FileDownloadSnackbar';
export default FileDownloadSnackbar;
