import { useCallback, useMemo } from 'react';
import env from '@beam-australia/react-env';
import NiceModal from '@ebay/nice-modal-react';
import {
    EndpointsRootLibraryModelsScheduleDetails,
    LibraryModelsFolder,
    LibraryModelsMedia,
    LibraryModelsMediaActions,
    LibraryModelsMediaStatus,
    LibraryModelsMediaTypes,
    PublishingCampaignSchedulingCampaignInfoWithMediaSchedules,
} from '@zetadisplay/engage-api-client';
import { DiscriminatedEntity, isMediaFile } from '@zetadisplay/engage-components/models';
import { useApi } from '@zetadisplay/engage-components/modules/api';
import {
    emitOnMediaFileDeleted,
    emitOnMediaFileEdited,
    emitOnMediaFileScheduled,
} from '@zetadisplay/engage-components/modules/library/events';
import { useWorkspace } from '@zetadisplay/engage-components/modules/workspaces';
import { createTemplateUrl } from '@zetadisplay/engage-components/utils/media';
import { useTranslation } from '@zetadisplay/zeta-localization';
import { ActionGroup } from '@zetadisplay/zeta-ui-components';
import { AxiosRequestConfig } from 'axios';
import { closeSnackbar, enqueueSnackbar } from 'notistack';

import DeleteMediaFilePrompt, {
    DeleteMediaFilePromptProps,
} from 'src/components/Modals/Library/MediaFile/DeleteMediaFilePrompt';
import ScheduleMediaFileForm, {
    ScheduleMediaFileFormProps,
} from 'src/components/Modals/Library/MediaFile/ScheduleMediaFileForm';
import UpdateMediaFileForm, {
    UpdateMediaFileFormProps,
} from 'src/components/Modals/Library/MediaFile/UpdateMediaFileForm';
import MoveLibraryItemSidekick, {
    MoveLibraryItemsSidekickProps,
} from 'src/components/Sidekicks/Library/MoveLibraryItemSidekick';
import { emitOnFileDownloadProgress } from 'src/modules/library/events/file-download-progress-event';

import useIsDisabledLibraryAction from './useIsDisabledLibraryAction';

const isTemplate = (media: DiscriminatedEntity<LibraryModelsFolder> | DiscriminatedEntity<LibraryModelsMedia>) => {
    return (
        isMediaFile(media) &&
        [LibraryModelsMediaTypes.TemplateHtml, LibraryModelsMediaTypes.TemplateZetaCast].includes(media.type)
    );
};

const isDownloadableMediaFile = (media: DiscriminatedEntity<LibraryModelsMedia>) => {
    return (
        isMediaFile(media) &&
        (media.type === LibraryModelsMediaTypes.Image ||
            media.type === LibraryModelsMediaTypes.Video ||
            media.type === LibraryModelsMediaTypes.TemplateZetaCast)
    );
};

const useLibraryListMediaActions = (): ActionGroup<DiscriminatedEntity<LibraryModelsMedia>>[] => {
    const shouldDisableAction = useIsDisabledLibraryAction();
    const t = useTranslation();
    const { workspace } = useWorkspace();
    const api = useApi();

    const onRemoveMediaFile = useCallback(
        (
            originalMedia: DiscriminatedEntity<LibraryModelsMedia>,
            response:
                | DiscriminatedEntity<LibraryModelsMedia>
                | (PublishingCampaignSchedulingCampaignInfoWithMediaSchedules | undefined)[]
        ) => {
            if (Array.isArray(response)) {
                return emitOnMediaFileEdited({
                    ...originalMedia,
                    campaignIds: originalMedia.campaignIds?.filter((playlistId) => {
                        return response.find((playlist) => playlist?.id === playlistId) === undefined;
                    }),
                });
            }

            return emitOnMediaFileDeleted(response);
        },
        []
    );

    const handleOpenInContentEditor = useCallback(
        (media: DiscriminatedEntity<LibraryModelsMedia>) => {
            window.open(createTemplateUrl(media, workspace, env('CONTENT_EDITOR_DOMAIN')), '_blank');
        },
        [workspace]
    );

    const handleMoveMediaFile = useCallback((media: DiscriminatedEntity<LibraryModelsMedia>) => {
        NiceModal.show<DiscriminatedEntity<LibraryModelsMedia>[], MoveLibraryItemsSidekickProps>(
            MoveLibraryItemSidekick,
            { subjects: [media] }
        ).then((response) => response.forEach((m) => emitOnMediaFileDeleted(m)));
    }, []);

    const handleEditMediaFile = useCallback((media: DiscriminatedEntity<LibraryModelsMedia>) => {
        NiceModal.show<DiscriminatedEntity<LibraryModelsMedia>, UpdateMediaFileFormProps>(UpdateMediaFileForm, {
            media,
        }).then(emitOnMediaFileEdited);
    }, []);

    const handleScheduleMediaFile = useCallback((media: DiscriminatedEntity<LibraryModelsMedia>) => {
        NiceModal.show<EndpointsRootLibraryModelsScheduleDetails[], ScheduleMediaFileFormProps>(ScheduleMediaFileForm, {
            media,
        }).then((response) =>
            emitOnMediaFileScheduled({ ...media, scheduleIds: response.map((schedule) => schedule.id) })
        );
    }, []);

    const handleDeleteMediaFile = useCallback(
        (media: DiscriminatedEntity<LibraryModelsMedia>) => {
            NiceModal.show<
                | DiscriminatedEntity<LibraryModelsMedia>
                | (PublishingCampaignSchedulingCampaignInfoWithMediaSchedules | undefined)[],
                DeleteMediaFilePromptProps
            >(DeleteMediaFilePrompt, { media }).then((response) => onRemoveMediaFile(media, response));
        },
        [onRemoveMediaFile]
    );

    const handleDownloadMediaFile = useCallback(
        (media: DiscriminatedEntity<LibraryModelsMedia>) => {
            const id = media.id + Date.now();

            const axiosOptions: AxiosRequestConfig = {
                responseType: 'blob',
                onDownloadProgress: (event) => emitOnFileDownloadProgress({ mediaId: id, progress: event.progress }),
            };

            enqueueSnackbar('', {
                key: id,
                persist: true,
                variant: 'FileDownloadSnackbar',
                fileName: media.name,
            });

            api.media
                .download({ mediaid: media.id, workspaceid: workspace.id }, axiosOptions)
                .then((response) => {
                    const type = response.headers['content-type'];
                    const blob = new Blob([response.data as unknown as Blob], { type });
                    const href = URL.createObjectURL(blob);

                    const link = document.createElement('a');
                    link.href = href;
                    link.setAttribute('download', media.name);
                    document.body.appendChild(link);
                    link.click();

                    document.body.removeChild(link);
                    URL.revokeObjectURL(href);
                })
                .catch((err) => {
                    closeSnackbar(id);
                    throw err;
                });
        },
        [api.media, workspace.id]
    );

    const isDisabledForOpenInContentEditor = useCallback(
        (media: DiscriminatedEntity<LibraryModelsMedia>) => {
            if (media.shared) {
                return (
                    shouldDisableAction(media, LibraryModelsMediaActions.Read, ['ENGAGE_LIBRARY_TEMPLATE_VIEW']) ||
                    !media.templateId
                );
            }

            return (
                shouldDisableAction(media, LibraryModelsMediaActions.Edit, ['ENGAGE_LIBRARY_TEMPLATE_VIEW']) ||
                !media.templateId
            );
        },
        [shouldDisableAction]
    );

    const isDisabledForMoveMediaFile = useCallback(
        (media: DiscriminatedEntity<LibraryModelsMedia>) => {
            return shouldDisableAction(media, LibraryModelsMediaActions.Move, [
                'ENGAGE_LIBRARY_VIEW',
                'ENGAGE_LIBRARY_CONTENT_EDIT',
            ]);
        },
        [shouldDisableAction]
    );

    const isDisabledForEditMediaFile = useCallback(
        (media: DiscriminatedEntity<LibraryModelsMedia>) => {
            return shouldDisableAction(media, LibraryModelsMediaActions.Edit, [
                'ENGAGE_LIBRARY_VIEW',
                'ENGAGE_LIBRARY_CONTENT_EDIT',
            ]);
        },
        [shouldDisableAction]
    );

    const isDisabledForScheduleMediaFile = useCallback(
        (media: DiscriminatedEntity<LibraryModelsMedia>) => {
            return shouldDisableAction(media, LibraryModelsMediaActions.Edit, ['ENGAGE_PLAYLIST_EDIT']);
        },
        [shouldDisableAction]
    );

    const isDisabledForDeleteMediaFile = useCallback(
        (media: DiscriminatedEntity<LibraryModelsMedia>) => {
            return shouldDisableAction(media, LibraryModelsMediaActions.Delete, [
                'ENGAGE_LIBRARY_VIEW',
                'ENGAGE_LIBRARY_CONTENT_DELETE',
            ]);
        },
        [shouldDisableAction]
    );

    const isDisabledForDownloadMediaFile = useCallback(
        (media: DiscriminatedEntity<LibraryModelsMedia>) => {
            return (
                media.processing ||
                media.status !== LibraryModelsMediaStatus.Ready ||
                shouldDisableAction(media, LibraryModelsMediaActions.Download, ['ENGAGE_LIBRARY_VIEW'])
            );
        },
        [shouldDisableAction]
    );

    return useMemo(
        () => [
            {
                actions: [
                    {
                        handler: handleOpenInContentEditor,
                        isDisabled: isDisabledForOpenInContentEditor,
                        isVisible: isTemplate,
                        label: t.trans('engage.templates.content-editor.open'),
                        name: 'open-in-editor',
                    },
                    {
                        handler: handleMoveMediaFile,
                        isDisabled: isDisabledForMoveMediaFile,
                        isVisible: isMediaFile,
                        label: t.trans('common.action.move'),
                        name: 'move',
                    },
                    {
                        handler: handleEditMediaFile,
                        isDisabled: isDisabledForEditMediaFile,
                        isVisible: isMediaFile,
                        label: t.trans('common.action.edit'),
                        name: 'edit',
                    },
                    {
                        handler: handleScheduleMediaFile,
                        isDisabled: isDisabledForScheduleMediaFile,
                        isVisible: isMediaFile,
                        label: t.trans('engage.action.schedule'),
                        name: 'schedule',
                    },
                    {
                        handler: handleDeleteMediaFile,
                        isDisabled: isDisabledForDeleteMediaFile,
                        isVisible: isMediaFile,
                        label: t.trans('common.action.delete'),
                        name: 'delete',
                    },
                    {
                        handler: handleDownloadMediaFile,
                        isDisabled: isDisabledForDownloadMediaFile,
                        isVisible: isDownloadableMediaFile,
                        label: t.trans('common.action.download'),
                        name: 'download',
                    },
                ],
                name: 'library-list-media-actions',
            },
        ],
        [
            handleDeleteMediaFile,
            handleEditMediaFile,
            handleMoveMediaFile,
            handleOpenInContentEditor,
            handleScheduleMediaFile,
            handleDownloadMediaFile,
            isDisabledForDeleteMediaFile,
            isDisabledForEditMediaFile,
            isDisabledForMoveMediaFile,
            isDisabledForOpenInContentEditor,
            isDisabledForScheduleMediaFile,
            isDisabledForDownloadMediaFile,
            t,
        ]
    );
};

export default useLibraryListMediaActions;
